import React from 'react'
import { useDrag } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { useFilterTypes } from 'lists/useFilterTypes'
import { Accordion, ContentBlock } from '@politechdev/blocks-design-system'
import { FILTER_SECTIONS } from '../constants'
import { useListFilters } from '../ListFilterProvider/ListFilterProvider'
import { FilterCardHeader } from '../FilterCard/FilterCard'
import styles from './FilterSelectionZone.module.scss'

const cx = classNames.bind(styles)

const FilterSelectionHandle = ({ filterType, groupId }) => {
  const [{ isDragging }, dragRef] = useDrag({
    item: { id: filterType.id, type: `${filterType.id}-${groupId}` },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  return (
    <div
      ref={dragRef}
      className={cx('handle', {
        'handle--isDragging': isDragging,
      })}
    >
      <FilterCardHeader filterType={filterType} />
    </div>
  )
}

const FilterSelectionSection = ({ section }) => {
  const { t } = useTranslation()
  const { group, availableFilters } = useListFilters()

  const sectionFilters = availableFilters.filter(
    filterType => filterType.sectionId === section.id
  )

  return (
    <ContentBlock>
      {sectionFilters.length ? (
        sectionFilters.map(filterType => (
          <FilterSelectionHandle
            key={filterType.id}
            groupId={group.id}
            filterType={filterType}
          />
        ))
      ) : (
        <span className={styles.empty}>{t('All filters in use')}</span>
      )}
    </ContentBlock>
  )
}

const FilterSelectionZone = () => {
  const { filterTypes } = useFilterTypes()
  const { t } = useTranslation()

  return (
    <div className={styles.zone}>
      <span className={styles.instructions}>
        Browse & drag filters to apply it to your list
      </span>
      <Accordion>
        {FILTER_SECTIONS.map(section =>
          filterTypes.some(filter => filter.sectionId === section.id) ? (
            <Accordion.Section label={t(section.name)}>
              <FilterSelectionSection key={section.id} section={section} />
            </Accordion.Section>
          ) : null
        )}
      </Accordion>
    </div>
  )
}

export default FilterSelectionZone
