import { useTranslation } from 'react-i18next'
import { View } from 'components'
import { Sheet, PageHeader } from '@politechdev/blocks-design-system'
import CampaignForm from '../CampaignForm/CampaignForm'

const CampaignCreate = () => {
  const { t } = useTranslation()

  return (
    <View>
      <Sheet>
        <PageHeader title={t('New Campaign')} />
        <CampaignForm />
      </Sheet>
    </View>
  )
}

export default CampaignCreate
