import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { View, CardError, Sheet, ViewContainer } from 'components'
import {
  Button,
  ButtonBlock,
  PageHeader,
} from '@politechdev/blocks-design-system'
import { useReactRouter } from 'hooks'
import { useCurrent } from 'contexts/index'
import UserTable from '../UserTable/UserTable'

const UserSelect = () => {
  const { t } = useTranslation()
  const { location } = useReactRouter()
  const { doesCurrentUserHavePermission } = useCurrent()
  const canViewUsers = doesCurrentUserHavePermission({
    resource: 'user',
    ability: 'view',
  })
  const canModifyUsers = doesCurrentUserHavePermission({
    resource: 'user',
    ability: 'modify',
  })

  if (!canViewUsers) {
    return (
      <CardError
        hide={canViewUsers}
        message={t('You do not have permission to view this page')}
        hideSupportLink
      />
    )
  }

  return (
    <View>
      <PageHeader title={t('Users')}>
        <ButtonBlock justify="right">
          <Link
            onClick={e => !canModifyUsers && e.preventDefault()}
            to={`${location.pathname}/new`}
          >
            <Button.Accent disabled={!canModifyUsers}>
              {t('Add user')}
            </Button.Accent>
          </Link>
        </ButtonBlock>
      </PageHeader>
      <ViewContainer>
        <Sheet>
          <UserTable />
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default UserSelect
