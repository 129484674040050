import { useTranslation } from 'react-i18next'
import { View } from 'components'
import { Sheet, PageHeader } from '@politechdev/blocks-design-system'
import TurfForm from '../TurfForm/TurfForm'

const TurfCreate = () => {
  const { t } = useTranslation()

  return (
    <View>
      <Sheet>
        <PageHeader title={t('New turf')} />
        <TurfForm />
      </Sheet>
    </View>
  )
}

export default TurfCreate
