import { useTranslation } from 'react-i18next'
import { View } from 'components'
import { Sheet, PageHeader } from '@politechdev/blocks-design-system'
import UserForm from '../UserForm/UserForm'

const UserCreate = () => {
  const { t } = useTranslation()

  return (
    <View>
      <Sheet>
        <PageHeader title={t('New user')} />
        <UserForm />
      </Sheet>
    </View>
  )
}

export default UserCreate
