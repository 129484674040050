import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { View, CardError } from 'components'
import {
  Sheet,
  ProgressBar,
  PageHeader,
} from '@politechdev/blocks-design-system'
import { useReactRouter, useRequest } from 'hooks'
import { fetchTurf } from 'requests/turfs'
import TurfForm from '../TurfForm/TurfForm'

const TurfEdit = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()

  const { makeRequest, isLoading, hasErrors, response, isRequestComplete } =
    useRequest(fetchTurf)

  const currentTurf = response ? response.turf : {}

  useEffect(() => {
    makeRequest(match.params.id)
  }, [match.params.id])

  const turfLoaded =
    isRequestComplete && !hasErrors && currentTurf.id === +match.params.id

  return (
    <View>
      <Sheet>
        <PageHeader title={t('Edit turf')} />
        <ProgressBar show={isLoading} />
        <CardError
          hide={!hasErrors}
          message={t("We're unable to retrieve this turf")}
        />
        {turfLoaded && <TurfForm turf={currentTurf} />}
      </Sheet>
    </View>
  )
}

export default TurfEdit
