import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SelectPanel, LoadBar } from 'components'
import {
  Button,
  ButtonBlock,
  Checkbox,
  Icon,
} from '@politechdev/blocks-design-system'
import { useReactRouter, useRequest } from 'hooks'
import { useCurrent } from 'contexts/index'
import { DEFAULT_LIST_FOLDER_NAME } from 'constants/lists'
import FolderShareControls from '../FolderShareControls/FolderShareControls'
import FolderShareModal from '../FolderShareModal/FolderShareModal'
import ListRemoveModal from '../ListRemoveModal/ListRemoveModal'
import ListMoveModal from '../ListMoveModal/ListMoveModal'
import { useListCheckboxes } from '../ListCheckboxProvider/ListCheckboxProvider'
import styles from './ListFolder.module.scss'

const ListFolder = ({ folder, numFolders, copyLists, getFolders }) => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const { toggleCheckbox, isChecked, getCheckedCheckboxValues, uncheckAll } =
    useListCheckboxes()

  const { currentUser } = useCurrent()

  const listsSelected = !!getCheckedCheckboxValues().length
  const isShared = folder.user_id !== currentUser.id
  const [shareModalOpen, setShareModalOpen] = useState(false)
  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const [moveModalOpen, setMoveModalOpen] = useState(false)

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest, isLoading } = useRequest(copyLists, {
    onSuccess: uncheckAll,
  })

  return (
    <SelectPanel
      title={folder.name}
      IconProp={Icon.FolderOpen}
      defaultClosed
      Actions={() => {
        if (isShared || folder.name === DEFAULT_LIST_FOLDER_NAME) return null
        return (
          <FolderShareControls
            folderSharees={folder.sharees}
            openModal={() => setShareModalOpen(true)}
          />
        )
      }}
    >
      <>
        <LoadBar show={isLoading} />
        {folder.lists.map(list => (
          <div key={list.id} className={styles.list}>
            <Checkbox
              id={list.id}
              name={`list-${list.id}`}
              className={styles.checkbox}
              checked={isChecked(list.id)}
              aria-label={list.name}
              disabled={isLoading}
              onChange={() => toggleCheckbox(list.id)}
            />
            <Link to={`${match.path}/${list.id}`}>
              <span className={styles.title}>{list.name}</span>
              <div className={styles.personCount}>
                <Icon.User alt="" />
                <span>{list.meta.total || 0}</span>
              </div>
            </Link>
          </div>
        ))}
        <div className={styles.footer}>
          <ButtonBlock justify="right">
            {numFolders > 1 && !isShared && (
              <Button.Secondary
                tooltipLabel={t('Move')}
                tooltipPosition="top"
                disabled={!listsSelected || isLoading}
                onClick={() => setMoveModalOpen(true)}
              >
                <Icon.UpDownLeftRight />
              </Button.Secondary>
            )}
            <Button.Secondary
              tooltipLabel={t('Copy')}
              tooltipPosition="top"
              disabled={!listsSelected || isLoading}
              onClick={() => {
                makeRequest(getCheckedCheckboxValues())
              }}
            >
              <Icon.Copy />
            </Button.Secondary>
            {folder.name !== DEFAULT_LIST_FOLDER_NAME && !isShared && (
              <Button.Secondary
                tooltipLabel={t('Remove')}
                tooltipPosition="top"
                disabled={!listsSelected || isLoading}
                onClick={() => setRemoveModalOpen(true)}
              >
                <Icon.MinusCircle />
              </Button.Secondary>
            )}
          </ButtonBlock>
        </div>
        <FolderShareModal
          folder={folder}
          isOpen={shareModalOpen}
          closeModal={async () => {
            await getFolders()
            setShareModalOpen(false)
          }}
        />
        <ListRemoveModal
          isOpen={removeModalOpen}
          closeModal={() => setRemoveModalOpen(false)}
        />
        <ListMoveModal
          folderId={folder.id}
          isOpen={moveModalOpen}
          closeModal={() => setMoveModalOpen(false)}
        />
      </>
    </SelectPanel>
  )
}

export default ListFolder
