import { useContext, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, Sheet } from 'components'
import { useReactRouter } from 'hooks'
import { PageHeader } from '@politechdev/blocks-design-system'
import { RoleAbilityProvider } from '../RoleAbilityContextProvider/RoleAbilityContextProvider'
import RolesSidebar from '../RolesSidebar/RolesSidebar'
import RolesSummary from '../RolesSummary/RolesSummary'
import RoleDetail from '../RoleDetail/RoleDetail'
import RoleAbilityDependencyModal from '../RoleAbilityDependencyModal/RoleAbilityDependencyModal'
import RoleAbilityDependantModal from '../RoleAbilityDependantModal/RoleAbilityDependantModal'
import RoleModal from '../RoleModal/RoleModal'
import styles from './Roles.module.scss'
import RoleModalStateProvider from '../RoleModalStateProvider/RoleModalStateProvider'
import { RoleContext } from '../RoleContext/RoleContext'

const Roles = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const { getRoles, rolesAreLoading } = useContext(RoleContext)

  useEffect(() => {
    getRoles()
  }, [])

  return (
    <View>
      <PageHeader title={t('Roles')} />
      <ViewContainer loading={rolesAreLoading}>
        <Sheet>
          <div className={styles.roles}>
            <RoleModalStateProvider>
              <RolesSidebar />
              <RoleAbilityProvider>
                <Switch>
                  <Route exact path={`${match.url}`} component={RolesSummary} />
                  <Route path={`${match.url}/:id`} component={RoleDetail} />
                </Switch>
                <RoleAbilityDependencyModal />
                <RoleAbilityDependantModal />
                <RoleModal />
              </RoleAbilityProvider>
            </RoleModalStateProvider>
          </div>
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default Roles
