import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { useForm } from 'contexts'
import { Font, Icon, Button } from '@politechdev/blocks-design-system'
import { useListFilters } from '../ListFilterProvider/ListFilterProvider'
import FilterCardField from '../FilterCardField/FilterCardField'
import styles from './FilterCard.module.scss'

const cx = classNames.bind(styles)

export const FilterCardHeader = ({ filterType, Action }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.header}>
      <div className={styles.label}>
        <Icon.Inbox />
        <span>{t(filterType.name)}</span>
      </div>
      {Action ? (
        <Action />
      ) : (
        <div className={styles.action}>
          <Icon.GripVertical />
        </div>
      )}
    </div>
  )
}

export const FilterCard = ({ filter }) => {
  const { t } = useTranslation()
  const {
    group,
    filters,
    removeFilter,
    startChaining,
    endChaining,
    addChainLink,
    removeChainLink,
    chainingFilter,
    filterTypesMap,
  } = useListFilters()

  const { fieldErrors } = useForm()

  const filterType = filterTypesMap[filter.id]

  const chainingEnabled = group.id !== 'requiredFilters'
  const { isChaining, beingChained, isChainable } = useMemo(() => {
    const isChaining = !!chainingFilter

    return {
      isChaining,
      beingChained:
        isChaining && chainingFilter.id === filter.id && !filter.chainId,
      isChainable:
        !filter.chainId ||
        !filters.find(
          ({ id, chainId }) =>
            filter.chainId === chainId && chainingFilter?.id === id
        ),
    }
  }, [filters, chainingFilter])

  return (
    <div className={styles.container}>
      <div className={cx('card', { 'card--invalid': fieldErrors[filter.id] })}>
        <FilterCardHeader
          filterType={filterType}
          Action={() => (
            <Button.Secondary onClick={() => removeFilter(filter)}>
              <Icon.Xmark />
            </Button.Secondary>
          )}
        />
        <div className={styles.body}>
          {!filter.isValid ? (
            <Font.Copy variant="hint" className={styles['card-error']}>
              {t('Filter is invalid. Please update or remove.')}
            </Font.Copy>
          ) : null}
          <FilterCardField filter={filter} />
          <div className={styles.warning}>
            {fieldErrors[filter.id] && fieldErrors[filter.id]}
          </div>
        </div>
      </div>
      {chainingEnabled && (
        <div className={styles.actions}>
          {!filter.chainId && !isChaining && (
            <Button.Secondary
              tooltipLabel={t('Chain to another filter')}
              onClick={() => startChaining(filter)}
            >
              <Icon.Link />
            </Button.Secondary>
          )}
          {isChaining && beingChained && (
            <>
              <Button.Secondary
                tooltipLabel={t('Cancel chaining')}
                onClick={() => endChaining()}
              >
                <Icon.Link />
              </Button.Secondary>
              <span className={styles.instructions}>
                {t('Click on a')} <Icon.LinkSimple />{' '}
                {t('to chain filters together')}
              </span>
            </>
          )}
          {filter.chainId && isChainable && (
            <>
              <Button.Secondary
                disabled={!isChaining}
                tooltipLabel={
                  isChaining
                    ? `Add to chain group ${filter.chainId}`
                    : `Chain group ${filter.chainId}`
                }
                onClick={() => addChainLink(filter)}
              >
                {`filter_${filter.chainId}`}
              </Button.Secondary>
              {!isChaining && (
                <Button.Secondary
                  tooltipLabel={t('Unchain')}
                  onClick={() => removeChainLink(filter)}
                >
                  <Icon.LinkSlash />
                </Button.Secondary>
              )}
            </>
          )}
          {!filter.chainId &&
            chainingFilter &&
            isChainable &&
            !beingChained && (
              <Button.Secondary
                tooltipLabel={t('Chain')}
                onClick={() => addChainLink(filter)}
              >
                <Icon.LinkSimple />
              </Button.Secondary>
            )}
        </div>
      )}
    </div>
  )
}
