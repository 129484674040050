import { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, CardError } from 'components'
import {
  Button,
  ButtonBlock,
  PageHeader,
  Switch,
} from '@politechdev/blocks-design-system'
import { ScrollProvider, useCurrent } from 'contexts'
import { useReactRouter, useRequest } from 'hooks'
import TurfTree from '../TurfTree/TurfTree'
import { TurfContext } from '../TurfContext/TurfContext'
import TurfsStickySection from './TurfsStickySection'
import styles from './TurfSelect.module.scss'

const TurfSelect = () => {
  const { t } = useTranslation()
  const { location } = useReactRouter()

  const observerRef = useRef()

  const { doesCurrentUserHavePermission } = useCurrent()
  const canViewTurfs = doesCurrentUserHavePermission({
    resource: 'turf',
    ability: 'view',
  })
  const canModifyTurfs = doesCurrentUserHavePermission({
    resource: 'turf',
    ability: 'modify',
  })

  const { rootTurfs, requestTurfs } = useContext(TurfContext)

  const [showArchived, setShowArchived] = useState(false)

  const { makeRequest, isLoading, hasErrors } = useRequest(requestTurfs)

  useEffect(() => {
    makeRequest({
      fields: [
        'id',
        'name',
        'parent_id',
        'lft',
        'rgt',
        'archived',
        'qc_config',
      ],
      filters: {
        rules: showArchived
          ? undefined
          : [
              {
                column: 'archived',
                operator: 'is',
                param: false,
              },
            ],
      },
    })
  }, [showArchived])

  if (!canViewTurfs) {
    return (
      <CardError
        hide={canViewTurfs}
        message={t('You do not have permission to view this page')}
        hideSupportLink
      />
    )
  }

  return (
    <View>
      <PageHeader title={t('Turfs')} className={styles.subheader}>
        <ButtonBlock justify="right">
          <Link
            onClick={e => !canModifyTurfs && e.preventDefault()}
            to={`${location.pathname}/new`}
          >
            <Button.Accent disabled={!canModifyTurfs}>
              {t('Add Turf')}
            </Button.Accent>
          </Link>
        </ButtonBlock>
      </PageHeader>
      <TurfsStickySection observerRef={observerRef}>
        <Switch
          label={t('Show archived turfs')}
          value={showArchived}
          onClick={() => {
            setShowArchived(old => !old)
          }}
        />
      </TurfsStickySection>
      <ViewContainer loading={isLoading}>
        <CardError
          hide={!hasErrors}
          message={t('An error occurred while fetching turfs')}
        />
        <TurfTree
          turfIds={rootTurfs.map(turf => turf.id)}
          observerRef={observerRef}
        />
      </ViewContainer>
    </View>
  )
}

export default () => (
  <DndProvider backend={HTML5Backend}>
    <ScrollProvider speed={2}>
      <TurfSelect />
    </ScrollProvider>
  </DndProvider>
)
